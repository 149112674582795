import imgT1 from "../assets/image/jpg/lisa/illustration/lisa.jpg";
import imgT19 from "../assets/image/jpg/lisa/illustration/dodie.jpg";
import imgT2 from "../assets/image/jpg/lisa/illustration/marti-fischer.jpg";
import imgT3 from "../assets/image/jpg/lisa/illustration/michi-dominik.jpg";
import imgT4 from "../assets/image/jpg/lisa/illustration/orla.jpg";
import imgT5 from "../assets/image/jpg/lisa/illustration/gunther-roswitha.jpg";
import imgT6 from "../assets/image/jpg/lisa/illustration/room.jpg";
import imgT7 from "../assets/image/jpg/lisa/corporate-design/Steiner_Mockup.jpg";
import imgT8 from "../assets/image/jpg/lisa/corporate-design/Steiner_Businesscard.jpg";
import imgT9 from "../assets/image/jpg/lisa/corporate-design/muchasgracias.jpg";
import imgT10 from "../assets/image/jpg/lisa/corporate-design/hihohoney.jpg";
import imgT12 from "../assets/image/jpg/lisa/corporate-design/Mockup_Nuggets.jpg";
import imgT13 from "../assets/image/jpg/lisa/corporate-design/vienna_front.jpg";
import imgT14 from "../assets/image/jpg/lisa/corporate-design/vienna_back.jpg";
import imgT15 from "../assets/image/jpg/lisa/corporate-design/vienna.jpg";
import imgT17 from "../assets/image/jpg/lisa/corporate-design/Mounthills_beer.jpg";
import imgT18 from "../assets/image/jpg/lisa/illustration/hazel.jpg";
import imgT20 from "../assets/image/jpg/lisa/illustration/jule.jpg";
import imgT21 from "../assets/image/jpg/lisa/illustration/frodo.jpg";
import imgT22 from "../assets/image/jpg/lisa/illustration/coolejule.jpg";
import imgT23 from "../assets/image/jpg/lisa/illustration/women.jpg";
import imgT24 from "../assets/image/jpg/lisa/illustration/man.jpg";
import imgT26 from "../assets/image/jpg/lisa/illustration/forest.jpg";
import imgT27 from "../assets/image/jpg/lisa/illustration/girls.jpg";
import imgT28 from "../assets/image/jpg/lisa/illustration/Moritz.jpg";
import imgT29 from "../assets/image/jpg/lisa/thesis/mockup_flyer_both.png";
import imgT30 from "../assets/image/jpg/lisa/thesis/mockup_tragetasche.jpg";
import imgT31 from "../assets/image/jpg/lisa/thesis/femalemock1.png";
import imgT32 from "../assets/image/jpg/lisa/thesis/malemock3.png";
import imgT33 from "../assets/image/jpg/lisa/corporate-design/Bildmarke_Mockup.png";
import imgT34 from "../assets/image/jpg/lisa/thesis/magazin_cover.png";
import imgT38 from "../assets/image/jpg/lisa/ux/slyskova/slyskovalogo.png";
import imgT42 from "../assets/image/jpg/lisa/ux/lhb/lhblogo.jpg";
import imgT44 from "../assets/image/jpg/lisa/ux/savory/thumbnail.png";
import imgT45 from "../assets/image/jpg/Portfolio_PNG_2022/HAMM02_Sticker.jpg";
import imgT46 from "../assets/image/jpg/Portfolio_PNG_2022/lifeconnect_logo.png";
import imgT47 from "../assets/image/jpg/Portfolio_PNG_2022/Hundefriseur_shampoo.jpg";
import imgT48 from "../assets/image/jpg/Portfolio_PNG_2022/Hundefriseur_Gutschein.jpg";
import imgT49 from "../assets/image/jpg/Portfolio_PNG_2022/leconnect_tasche.jpg";
import imgT50 from "../assets/image/jpg/Portfolio_PNG_2022/Physio_Tasse.jpg";
import imgT51 from "../assets/image/jpg/Portfolio_PNG_2022/frightnight_flyer.jpg";
import imgT52 from "../assets/image/jpg/Portfolio_PNG_2022/cookbook_timmy.jpg";
import imgT11 from "../assets/image/jpg/Portfolio_PNG_2022/Chickenchickenpizza_Drinks.jpg";
import imgT53 from "../assets/image/jpg/Portfolio_PNG_2022/dongsi_dängsi_mockup.jpg";
import imgT54 from "../assets/image/jpg/Portfolio_PNG_2022/plantapp_home.jpg";
import imgT55 from "../assets/image/jpg/Portfolio_PNG_2022/gameapp_home.jpg";
import imgT56 from "../assets/image/jpg/Portfolio_PNG_2022/READ01.jpg";
import imgT57 from "../assets/image/jpg/Portfolio_PNG_2022/ZETT02.jpg";
import imgT58 from "../assets/image/jpg/Portfolio_PNG_2022/youngcouple_portrait.png";




export const designWorks1 = [
  {
    brand: `Geknipst`,
    slug: `geknipst`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT45,
    link : "/project/geknipst",
    actionText: "Show project",
    actionBG: "#D6B6A4"
  },
  {
    brand: `Chickenchickenpizza Drinks`,
    slug: `chickenchickenpizza-drinks`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT11,
    link : "/project/chickenchickenpizza",
    actionText: "Show project",
    actionBG: "#7da5b5"
  },
  {
    brand: `Just Right Thesis`,
    slug: `just-right`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT29,
    link : "/project/thesis",
    actionText: "Show thesis",
    actionBG: "#1F3430"
  },
  {
    brand: `Dogwashing`,
    slug: `dogwashing`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT47,
    link : "/project/dogwashing",
    actionText: "Show project",
    actionBG: "#EFBBC0"
  },
  {
    brand: `Fright Night`,
    slug: `frightnight`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT51,
    link : "/project/frightnight",
    actionText: "Show project",
    actionBG: "#4D5157"
  },
  {
    brand: `Plantify`,
    slug: `fplantify`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["ux-design"],
    thumbnail: imgT54,
    link : "/project/plantify",
    actionText: "Show project",
    actionBG: "#AE5C5C"
  },
  {
    brand: `Hiho Honey`,
    slug: `hiho-honey`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT10,
    link : "/project/hihohoney",
    actionText: "Show project",
    actionBG: "#966F30"
  },
  {
    brand: `Dongsi Dängsi`,
    slug: `dongsidängsi`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT53,
    link : "/project/dongsidaengsi",
    actionText: "Show project",
    actionBG: "#B2A1F9"
  },
  {
    brand: `Physio Neumann`,
    slug: `physioneumann`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT50,
    link : "/project/physioneumann",
    actionText: "Show project",
    actionBG: "#618AAB"
  },
  {
    brand: `Cooking with Alex`,
    slug: `cookingwithalex`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT52,
    link : "/project/cookingwithalex",
    actionText: "Show project",
    actionBG: "#F49E71"
  },
  {
    brand: `lifeconnect`,
    slug: `lifeconnect`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT46,
    link : "/project/lifeconnect",
    actionText: "Show project",
    actionBG: "#966CE4"
  },
  {
    brand: `Board Masters`,
    slug: `boardmasters`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["ux-design"],
    thumbnail: imgT55,
    link : "/project/boardmasters",
    actionText: "Show project",
    actionBG: "#b2795c"
  },
  {
    brand: `Vienna Coffe House`,
    slug: `vienna-coffe-house-front`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT13,
    link : "/project/vienna",
    actionText: "Show project",
    actionBG: "#968B8D"
  },
  {
    brand: `le-connect`,
    slug: `leconnect`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT49,
    link : "/project/le-connect",
    actionText: "Show project",
    actionBG: "#7da5b5"
  },
  {
    brand: `Mounthills`,
    slug: `mounthills`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT17,
    link : "/project/mounthills",
    actionText: "Show project",
    actionBG: "#252525"
  },
  {
    brand: `Beauty Magazine`,
    slug: `beauty-magazine`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT34,
    link : "/project/magazine",
    actionText: "Show project",
    actionBG: "#868686"
  },
  {
    brand: `Muchas Gracias`,
    slug: `muchas-gracias`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT9,
    link : "/project/muchasgracias",
    actionText: "Show project",
    actionBG: "#B19A92"
  },
  {
    brand: `Steiner Mockup`,
    slug: `steinermockup`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["branding"],
    thumbnail: imgT8,
    link : "/project/steiner",
    actionText: "Show project",
    actionBG: "#716F6E"
  },
  {
    brand: `Savory Website`,
    slug: `savory-website`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["ux-design"],
    thumbnail: imgT44,
    link : "/project/savory",
    actionText: "Show project",
    actionBG: "#EA9B6E"
  },
  {
    brand: `Slyskova Website`,
    slug: `slyskova-website`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["ux-design"],
    thumbnail: imgT38,
    link : "/project/slyskova",
    actionText: "Show project",
    actionBG: "#7D8DCD"
  },
  {
    brand: `LHB Website`,
    slug: `lhb-website`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["ux-design"],
    thumbnail: imgT42,
    link : "/project/lhb",
    actionText: "Show project",
    actionBG: "#71A9C7"
  },
  {
    brand: `Self Portrait`,
    slug: `self-portrait`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT1,
    link : "/portfolio-details",
  },
  {
    brand: `Jule`,
    slug: `jule`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT20,
    link : "/portfolio-details",
  },
  {
    brand: `Dodie`,
    slug: `dodie`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT19,
    link : "/portfolio-details"
  },
  {
    brand: `Orla Gartland`,
    slug: `orla-gartland`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT4,
    link : "/portfolio-details",
  },
  {
    brand: `Young Couple Portrait`,
    slug: `young-couple-portrait`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT58,
    link : "/portfolio-details",
  },
  {
    brand: `Marti Fischer`,
    slug: `marti-fischer`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT2,
    link : "/portfolio-details",
  },
  {
    brand: `Michi & Dominik`,
    slug: `michi-dominik`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT3,
    link : "/portfolio-details",
  },
  {
    brand: `Family Portrait`,
    slug: `family-portrait`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT56,
    link : "/portfolio-details",
  },
  {
    brand: `Couple Portrait`,
    slug: `couple-portrait`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT57,
    link : "/portfolio-details",
  },
  {
    brand: `Frodo`,
    slug: `frodo`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT21,
    link : "/portfolio-details",
  },
  {
    brand: `Room`,
    slug: `room`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT6,
    link : "/portfolio-details",
  },
  {
    brand: `Moritz`,
    slug: `moritz`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT28,
    link : "/portfolio-details",
  },
  {
    brand: `Coole Jule`,
    slug: `coole-jule`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT22,
    link : "/portfolio-details",
  },
  {
    brand: `Forest`,
    slug: `forest`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT26,
    link : "/portfolio-details",
  },
  {
    brand: `Women`,
    slug: `women`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT23,
    link : "/portfolio-details",
  },
  {
    brand: `Man`,
    slug: `man`,
    title: `Adaptable but Identifiable Product for Kandinsky.`,
    categories: ["illustration"],
    thumbnail: imgT24,
    link : "/portfolio-details",
  },
  /* 2023 */

  
  
];
