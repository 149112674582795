import React from "react";
import { Link } from "gatsby"
import styled from "styled-components";
import { Box } from "../Core";

const ActionBtn = styled(Box)`
  width: 100%;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
  background: ${props => props.color};
  position: relative;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 0.9rem;
  cursor: pointer;
  bottom: 0px;
  opacity: 1;
  transition: 0.2s all ease;
  
  &:hover {
    opacity: 0.8;
  }
`;

const WorkCardActionButton = ({ text, link, color }) => (
    <Link to={link}>
        <ActionBtn color={color}>
            { text }
        </ActionBtn>
    </Link>
);

export default WorkCardActionButton;
